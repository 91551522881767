import { http } from '@/utils/http-provider';

const fiflboardApi = {
  v1: {
    getBanners: () => {
      return http.get('/v1/fiflboard/banners/');
    },
    getCompetitions: () => {
      return http.get('/v1/fiflboard/competitions/');
    },
    getDivisions: () => {
      return http.get('/v1/fiflboard/divisions/');
    },
    getWorkouts: () => {
      return http.get('/v1/fiflboard/workouts/');
    },
    getDefaultCompetition: () => {
      return http.get('/v1/fiflboard/competitions/default/');
    },
    getParticipants: params => {
      return http.get('/v1/fiflboard/participants/', { params });
    }
  }
};

export { fiflboardApi };
