import axios from 'axios';
import qs from 'qs';

const http = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  validateStatus: function(status) {
    return status >= 200 && status < 300;
  },
  paramsSerializer: function(params) {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  }
});

export { http };
