<template>
  <section>
    <div
      class="empty-table-wrapper desktop"
      @scroll="setScollToViewPosition"
      :style="{borderRadius : HasParent ? '0 0 10px 10px' : '10px', pointerEvents : Loading ? 'none' :'auto' }"
    >
      <section
        class="empty-table__scroll-container"
        :style="{ minWidth : emptyTableMinWidth + 'px'}"
      >
        <section
          class="empty-table-header"
          :style="{opacity: Loading? '0.3' : '1'}"
        >
          <div
            class="empty-table-header__content"
            v-for="item in ColumnData"
            :key="item.id"
            :style="{width: item.width}"
          >
            {{ item.label }}
          </div>
        </section>
        <section
          class="empty-table-body__fake"
          :style="{height: EmptyBodyHeight + 'px'}"
        />
      </section>
      <div
        class="empty-table-body__fixed"
        ref="emptyRef"
        :style="{height: 'calc(100% - ' + propsHeaderHeight + 'px)', top: propsHeaderHeight+'px' }"
      >
        <el-spinner 
          v-if="Loading"
          :v-else="85"
          :stroke-width="2"
        />
        <warning-message 
          v-else-if="!Loading && IsIcon" 
          :message="Message" 
        />
        <h3 
          v-else-if="!Loading && !IsIcon"
          class="empty-guide-text" 
        >
          {{ Message }}
        </h3>
      </div>
    </div>
    <div
      class="empty-table-wrapper mobile"
      @scroll="setScollToViewPosition"
      :style="{borderRadius : HasParent ? '0 0 10px 10px' : '10px', pointerEvents : Loading ? 'none' :'auto' }"
    >
      <section
        class="empty-table__scroll-container"
        :style="{ minWidth : emptyTableMinWidth + 'px'}"
      >
        <section
          class="empty-table-header"
          :style="{opacity: Loading? '0.3' : '1'}"
        >
          <div
            class="empty-table-header__content"
            v-for="item,idx in MobileColumData"
            :key="item.id"
            :style="{width: item.width}"
          >
            {{ idx === 2 ? mbColLabel : item.label }}
          </div>
        </section>
        <section
          class="empty-table-body__fake"
          :style="{height: EmptyBodyHeight + 'px'}"
        />
      </section>
      <div
        class="empty-table-body__fixed"
        ref="emptyRef"
        :style="{height: 'calc(100% - ' + propsHeaderHeight + 'px)', top: propsHeaderHeight+'px' }"
      >
        <el-spinner 
          v-if="Loading"
          :v-else="85"
          :stroke-width="2"
        />
        <warning-message 
          v-else-if="!Loading && IsIcon" 
          :message="Message" 
        />
        <h3 
          v-else-if="!Loading && !IsIcon"
          class="empty-guide-text" 
        >
          {{ Message }}
        </h3>
      </div>
    </div>
  </section>
</template>

<script>
import WarningMessage from '@/components/element-ui/WarningMessage';

export default {
  data(){
    return {
      ColumnData : [
        { id:0, label: 'RANK', width: 100 + 'px' }, 
        { id:1, label: 'NAME', width: 300 + 'px' },
        { id:2, label: 'POINT', width: 100 + 'px' },
        { id:3, label: 'WORKOUT 1', width: 200 + 'px' },
        { id:4, label: 'WORKOUT 2', width: 200 + 'px' },
        { id:5, label: 'WORKOUT 3', width: 200 + 'px' },
        { id:6, label: 'WORKOUT 4', width: 200 + 'px' }
      ],
      MobileColumData : [
        { id:0, label: 'RANK', width: '20%' }, 
        { id:1, label: 'NAME', width: '30%' },
        { id:2, label: 'POINT', width: '50%' }
      ],
      EmptyBodyHeight : 400,
      Message : this.propsMessage,
      HeaderHeight : this.propsHeaderHeight,
      HasParent: this.propsHasParent,
      IsIcon : this.propsIsIcon,
      label : this.label,
      emptyTableMinWidth : this.propsMinWidth
    };
  },
  computed:{
    Loading:function(){
      return this.propsLoadingStatus;
    }
  },
  components: { WarningMessage }, 
  props:{
    propsTableBodyHeight : {
      type: Number,
      default: 100
    },
    propsMessage : {
      type: String,
      default: '검색 결과가 없습니다.'
    },
    propsHeaderHeight : {
      type: Number,
      default: 43
    },
    propsHasParent:{
      type: Boolean,
      default: false
    },
    propsIsIcon : {
      type: Boolean,
      default: true
    },
    propsLoadingStatus:{
      type: Boolean,
      default: false
    },
    mbColLabel : {
      type : String,
      default: 'POINT'
    }
  },
  methods:{
    setScollToViewPosition:function(event){
      event.preventDefault();
      const TABLE_MAX_WIDTH = 1000;
      const { scrollLeft } = event.target;
      const { style: refStyle, clientWidth } = this.$refs.emptyRef;
      const isOverScroll = clientWidth + scrollLeft > TABLE_MAX_WIDTH; 
            
      //below logic is to respond to safari crossbrowsing.(overscroll bouncing)
      if(isOverScroll){
        return;
      }
      refStyle.left = scrollLeft + 'px';
             
    }
  }
};
</script>

<style lang="scss">
    .empty-table-wrapper.mobile {
      display: none;
    }

    .empty-table-wrapper{
        position: relative;
        width:100%;
        background-color: white;
        overflow: auto;
        border: 1px solid #d3d3d3;
        .empty-table__scroll-container{
            position: relative;
            overflow: hidden;
            min-width: 1000px;
            .empty-table-header{
                display: flex;
                flex-wrap: nowrap;
                border-bottom: 1px solid #d3d3d3;
                min-height: 46px;
                    .empty-table-header__content{
                        margin : auto 0;
                        font-size: 12px;
                        height: 19px;
                        font-stretch: normal;
                        font-style: normal;
                        padding: 10px;
                        line-height: 1.5;
                        letter-spacing: -0.46px;
                        color: #000;
                        text-align: center;
                        font-family: Montserrat, sans-serif;
                      }
                } 

        }
        .empty-table-body__fixed{
            position: absolute;
            width:100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .empty-guide-text{
            font-size: 14px;
            font-weight: 400;
            color : #909399;
            margin:0;
            letter-spacing: -0.8px;
        }
    }

    @media screen and (max-width: 768px){
        .empty-table-wrapper.mobile {
          display: block;
        }
        .empty-table-wrapper.desktop {
          display: none;
        }

        .empty-table-wrapper.mobile{
          .empty-table__scroll-container{
              min-width: initial;
              width: 100%;
              .empty-table-header__content{
                  font-size: 9px;
                  height: initial;
                  &:last-child{
                    text-align: justify;
                  }
              }
              
          }
        }
        
    }
</style>
