<template>
  <div class="warning-message-container">
    <div class="ic-warning" />
    <span
      class="warning-message"
      v-html="message"
    />
  </div>
</template>

<script>
export default {
  name: 'WarningMessage',
  props: {
    message: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss">
.warning-message-container {
	text-align: center;
	.ic-warning {
		width: 41px;
		height: 41px;
		margin: 0 auto 28px;
	}

	.warning-message {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.92px;
    text-align: center;
    color: #9b9b9b;
	}
}

@media screen and (max-width: 768px){
  .warning-message-container {
        .warning-message{
          font-size: 13px;
        }
  }
}
</style>
