<template>
  <div id="app">
    <header>
      <!-- NAVIGATION -->
      <nav>
        <img
          src="image/logo/fiflboard_logo.svg"
          @click.prevent="handleLogoClick"
        >
      </nav>
      <section id="fiflboard__banner-container">
        <el-carousel
          v-show="banners.length"
          trigger="click"
          indicator-position="none"
          :interval="5000"
        >
          <el-carousel-item
            v-for="item in banners"
            :key="item.id"
          >
            <img
              class="carousel-banners"
              :src="item.src"
              @click.prevent="handleBannerImageClick(item.link)"
            >
          </el-carousel-item>
        </el-carousel>
      </section>
    </header>
    <main>
      <section>
        <article class="fiflboard__filter--header">
          <h1>
            리더보드 아카이브
          </h1>
          <button
            class="filter-open-btn"
            @click="handleMobileFilterBox"
          >
            필터
          </button>
        </article>
        <!-- DESKTOP_FILTER -->
        <article class="fiflboard__filter--wrapper desktop">
          <el-select
            class="fiflboard__filter--year"
            v-model="selectedYear"
            placeholder="연도"
          >
            <el-option
              class="desktop__option--year"
              v-for="year in yearOptions"
              :key="year"
              :label="year"
              :value="year"
            />
          </el-select>
          <el-select
            v-model="selectedCompetition"
            class="fiflboard__filter--competition"
            placeholder="대회"
          >
            <el-option
              class="desktop__option--competition"
              v-for="option in competitionOptions"
              :key="option.id"
              :label="option.name"
              :value="option.id"
            />
          </el-select>
          <el-select
            v-model="selectedDivision"
            class="fiflboard__filter--division"
            placeholder="대회 부문"
          >
            <el-option
              class="desktop__option--division"
              v-for="division in divisionOptions"
              :key="division.id"
              :label="division.name"
              :value="division.id"
            />
          </el-select>
          <div
            class="fiflboard__search--search-box input-search el-input el-input--prefix"
          >
            <input
              type="text"
              class="el-input__inner"
              placeholder="참가팀 또는 참가자 검색"
              :value="searchKeyword"
              @input="handleInputSearchKeyword"
              @click="clearSearchKeyword"
            >
            <span class="el-input__prefix">
              <i class="el-input__icon el-icon-search" />
            </span>
          </div>
        </article>
        <transition name="fade">
          <!-- MOBILE FILTER -->
          <article
            v-if="isMobileFilterVisible"
            class="fiflboard__filter--wrapper mobile"
          >
            <el-select
              v-model="selectedYear"
              class="fiflboard__filter--year"
              placeholder="연도"
            >
              <el-option
                class="mobile__option"
                v-for="year in yearOptions"
                :style="{width: optionsWidth + 'px'}"
                :key="year"
                :label="year"
                :value="year"
              />
            </el-select>
            <el-select
              v-model="selectedCompetition"
              class="fiflboard__filter--competition"
              placeholder="대회"
            >
              <el-option
                class="mobile__option"
                :style="{width: optionsWidth + 'px'}"
                v-for="competition in competitionOptions"
                :key="competition.id"
                :label="competition.name"
                :value="competition.id"
              />
            </el-select>
            <el-select
              v-model="selectedDivision"
              class="fiflboard__filter--division"
              placeholder="대회 부문"
            >
              <el-option
                class="mobile__option"
                :style="{width: optionsWidth + 'px'}"
                v-for="division in divisionOptions"
                :key="division.id"
                :label="division.name"
                :value="division.id"
              />
            </el-select>
            <el-select
              v-model="selectedWorkout"
              class="fiflboard__filter--sort"
              placeholder="정렬"
            >
              <el-option
                class="mobile__option"
                :style="{width: optionsWidth + 'px'}"
                v-for="workout in workoutOptions"
                :key="workout.id"
                :label="workout.name"
                :value="workout.id"
              />
            </el-select>
            <div
              class="fiflboard__search--search-box input-search el-input el-input--prefix"
            >
              <input
                type="text"
                class="el-input__inner"
                placeholder="참가팀 또는 참가자 검색"
                :value="searchKeyword"
                @input="handleInputSearchKeyword"
                @click="clearSearchKeyword"
              >
              <span class="el-input__prefix">
                <i class="el-input__icon el-icon-search" />
              </span>
            </div>
            <el-button
              class="search-btn"
              @click="handleMobileFilterClick"
            >
              적용
            </el-button>
          </article>
        </transition>
      </section>
      <!-- TABLE DESKTOP -->
      <section class="main__table-container">
        <empty-table
          v-show="participants.length === 0"
          :mb-col-label="emptyColLabel"
        />
        <div v-show="participants.length">
          <el-table
            class="main__table--desktop"
            v-loading="isStartFetchData"
            :data="participants"
            :row-style="{color:'#fff', backgroundColor:'#000'}"
            @header-click="handleDesktopWorkoutFilter"
          >
            <el-table-column
              prop="sum.rank"
              label="RANK"
              width="110"
              align="center"
            />
            <el-table-column
              prop="athlete.name"
              label="NAME"
              width="280"
            />
            <el-table-column
              label="POINT"
            >
              <template slot-scope="scope">
                {{ `${scope.row.sum.score}p` }}
              </template>
            </el-table-column>
            <el-table-column
              v-for="workout, idx in colWorkouts"
              :key="workout.id"
              :label="workout.workout.name"
              width="155"
            >
              <template
                slot-scope="scope"
                :index="idx"
              >
                <section class="col--detail-score">
                  <strong>{{ scope.row.records[idx].rank }}</strong>
                  <p>{{ `${scope.row.records[idx].display_score}(${scope.row.records[idx].tie_breaker ? scope.row.records[idx].tie_breaker : '--'})${scope.row.records[idx].points}p` }}</p>
                </section>           
              </template>
            </el-table-column>
          </el-table>
          <!-- TABLE MOBILE -->
          <el-table
            class="main__table--mobile"
            v-loading="isStartFetchData"
            :data="participants"
            :row-style="{color:'#fff', backgroundColor:'#000'}"
          >
            <el-table-column
              prop="sum.rank"
              label="RANK"
              align="center"
            />
            <el-table-column
              prop="athlete.name"
              label="NAME"
            />
            <el-table-column
              v-if="!targetColumnData.length"
              label="POINT"
            >
              <template slot-scope="scope">
                {{ `${scope.row.sum.score}p` }}
              </template>
            </el-table-column>
            <el-table-column
              v-else
              :label="targetColumnData[0].workout.name"
            >
              <template
                slot-scope="scope"
              >
                <section class="col--detail-score">
                  <strong>{{ targetColumnData[scope.$index].rank }}</strong>
                  <p>{{ `${targetColumnData[scope.$index].display_score}(${targetColumnData[scope.$index].tie_breaker ? targetColumnData[scope.$index].tie_breaker : '--'})${targetColumnData[scope.$index].points}p` }}</p>
                </section>           
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :page-size="page.limit"
            :current-page.sync="page.curr"
            layout="prev, pager, next"
            :total="page.totalRecords"
            @current-change="handleChangePageNumber"
          />
        </div>
      </section>
    </main>
    <footer>
      <article>
        <a
          id="footer-logo__anchor--fifl"
          :href="footerLink.FIFL.LOGO"
        />
        <div id="footer-logo__anchor--divider" />
        <a
          id="footer-logo__anchor--kboxrise"
          :href="footerLink.KBOXLISE"
        />
      </article>
      <article>
        <a
          id="anchor--fiflInc"
          :href="footerLink.FIFL.LINK1"
        >
          © FIFL Inc.
        </a>
      </article>
      <article>
        <div>
          <h5>FIFL</h5>
          <ul>
            <li>
              <a
                id="anchor--fiflbox-free"
                :href="footerLink.FIFLBOX"
              >
                피플박스 무료가입
              </a>
            </li>
            <li>
              <a
                id="anchor--fifl-team"
                :href="footerLink.TEAM"
              >
                팀 소개
              </a>
            </li>
            <li>
              <a
                id="anchor--help-center"
                :href="footerLink.HELP_CENTER"
              >
                헬프 센터
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h5>CONTACT</h5>
          <ul>
            <li>
              <a
                id="anchor--fifl-instagram"
                :href="footerLink.INSTAGRAM"
              >
                인스타그램
              </a>
            </li>
            <li>
              <a
                id="anchor--fifl-email"
              >
                문의
              </a>
            </li>
          </ul>
        </div>
      </article>

      <article>
        <strong id="simple-message">
          대한민국 크로스핏 커뮤니티를 응원합니다.
          <br>powered by <a :href="footerLink.FIFL.LINK2">
            FIFL
          </a>
        </strong>
      </article>
    </footer>
  </div>
</template>

<script>
import EmptyTable from '@/components/EmptyTable.vue';
import _ from 'lodash';
import { fiflboardApi } from '@/utils/api';

export default {
  name: 'App',
  components : {
    EmptyTable
  },
  data: function(){
    return {
      page : {
        limit : 30,
        curr: 1,
        totalRecords: 0
      },
      optionsWidth: window.innerWidth - 40,
      workouts: [{ id:-1,name:'TOTAL POINTS' }],
      colWorkouts: [],
      years: [],
      banners: [],
      divisions: [],
      competitions: [],
      participants: [],
      targetColumnData: [],
      isMobileFilterVisible: false,
      footerLink : {
        FIFL:{
          LOGO: 'https://bit.ly/2Zk2l0Z',
          LINK1: 'https://bit.ly/3CFS1z2',
          LINK2: 'https://bit.ly/2XL7QFR'
        },
        KBOXLISE:'http://www.kboxrise.com/k/',
        FIFLBOX:'https://bit.ly/3AEMesE',
        TEAM:'https://bit.ly/2XL70Jd',
        HELP_CENTER:'https://bit.ly/3EKIGb2',
        INSTAGRAM:'https://bit.ly/2Zkq0yp',
        MAIL:'mailto:contact@fiflfifl.com'
      },
      params: {},
      searchKeyword: '',
      emptyColLabel: 'POINT',
      // options
      yearOptions:[],
      competitionOptions: [],
      divisionOptions:[],
      workoutOptions:[],
      // selected
      selectedYear : '',
      selectedDivision: '',
      selectedCompetition: '',
      selectedWorkout: -1,
      // fetchDataTrigger
      isStartFetchData: false 
    };
  },
  mounted(){
    window.Intercom('boot', {
      app_id: 'xq49oldj'
    });
    window.Intercom('update');
    if(this.isMobile()){
      window.addEventListener('resize',this.handleResize);
    }
  },
  beforeDestroy() {
    if(this.isMobile()){
      window.removeEventListener('resize', this.handleResize);
    }
  },
  async created(){
    if(window.location.host !== process.env.VUE_APP_ALLOW_HOST){
      window.location.href = 'https://kboxrise.fiflboard.com';
      return;
    }

    // get Datas from Server
    const { data : banners } = await fiflboardApi.v1.getBanners();
    const { data : workouts } = await fiflboardApi.v1.getWorkouts();
    const { data : divisions } = await fiflboardApi.v1.getDivisions();
    const { data : competitions } = await fiflboardApi.v1.getCompetitions();
    const { data : defaultCompetition } = await fiflboardApi.v1.getDefaultCompetition();

    // set Data in Stores
    this.banners = banners;
    this.workouts = workouts;
    this.divisions = divisions;
    this.competitions = competitions;

    if(defaultCompetition){
      const { year, competition : competitionId, division, workout } = defaultCompetition;
      // 1. get/set Year by compeititons
      this.yearOptions = await this.getYearsByCompetitions(this.competitions);
      this.setYear(year);

      // 2. get/set Competition by year
      this.competitionOptions = await this.getCompetitonsByYear(this.competitions, year);
      this.setCompetiton(competitionId);

      // 3. get/set Divison by competiton
      this.divisionOptions = await this.getDivisionsByCompetitonId(this.divisions,competitionId);
      this.setDivision(division);

      // 4. get/set Workout by competition
      this.workoutOptions = await this.getWorkoutsByCompetitionId(this.workouts, competitionId);
      this.setWorkout(workout);

      this.isStartFetchData = true;
    }
  },

  watch:{
    async selectedYear(year){
      this.competitionOptions = await this.getCompetitonsByYear(this.competitions, year);
      const { id : competitonId } = this.competitionOptions[0];
      await this.setCompetiton(competitonId);
      await this.setWorkout(-1);

      if(!this.isMobile()){
        await this.resetPageNumber();
        await this.resetSearchKeyword();  
        this.isStartFetchData = true;
      }
    },
    async selectedCompetition(competitionId){
      this.divisionOptions = await this.getDivisionsByCompetitonId(this.divisions, competitionId);
      const { id :divisionId } = this.divisionOptions[0];
      await this.setDivision(divisionId);

      this.workoutOptions = await this.getWorkoutsByCompetitionId(this.workouts, competitionId);
      await this.setWorkout(-1);

      if(!this.isMobile()){
        await this.resetPageNumber();
        await this.resetSearchKeyword();  
        this.isStartFetchData = true;
      }
    },
    async selectedDivision(){
      if(!this.isMobile()){
        await this.setWorkout(-1);
        this.isStartFetchData = true;
      }
    },
    async isStartFetchData(event){
      if(event){
        console.log('fetch start');
        const params =  {
          ...this.params,
          workout: this.selectedWorkout,
          division: this.selectedDivision,
          competition: this.selectedCompetition,
          limit: this.page.limit,
          offset: (this.page.curr - 1) * this.page.limit
        };

        await this.fetch(params);
        this.isStartFetchData = false;
      }
    }
  },
  methods:{
    // option getter
    getYearsByCompetitions(competitions){
      const years = new Set(competitions.map(({ year })=>year));
      return years;
    },
    getCompetitonsByYear(competitions, year){
      return competitions.filter(competition => competition.year === year);
    },
    getDivisionsByCompetitonId(divisions, competitionId){
      return divisions.filter(({ competitions })=> competitions.includes(competitionId));
    },
    getWorkoutsByCompetitionId(workouts, competitionId){
      return [
        { id:-1,name:'TOTAL POINTS' }, ...workouts.filter(({ competitions }) => competitions.includes(competitionId))
      ];
    },
    // selected Value setter
    setYear(year){
      this.selectedYear = year;
    },
    setCompetiton(competition){
      this.selectedCompetition = competition; 
    },
    setDivision(division){
      this.selectedDivision = division;
    },
    setWorkout(workout){
      this.selectedWorkout = workout;
    },
    // initialized values
    resetPageNumber(){
      this.setWorkout(-1);
      this.page.curr = 1;
    },
    resetMobilePageNumber(isSelectedDefaultWorkout){
      if(isSelectedDefaultWorkout){
        this.setWorkout(-1);
      }
      this.page.curr = 1;
    },
    resetSearchKeyword(){
      if('search' in this.params){
        delete this.params.search;
      }
    },
    // handlers
    handleLogoClick(){
      window.location.href = 'https://kboxrise.fiflboard.com';
    },
    handleMobileFilterBox(){
      this.isMobileFilterVisible = !this.isMobileFilterVisible;
    },
    handleBannerImageClick(link){
      window.open(link);
    },
    handleInputSearchKeyword(event){
      this.searchKeyword = event.target.value;
      this.searchDataByKeyword();
    },
    async handleMobileFilterClick(){
      this.emptyColLabel = this.selectedWorkout === -1 ? 'POINT' : this.workoutOptions.filter(workout=> workout.id === this.selectedWorkout)[0].name;
      if(!this.searchKeyword || !this.searchKeyword.length){
        await this.resetSearchKeyword();
      }
      const isSelectedDefaultWorkout = this.selectedWorkout === -1;
      await this.resetMobilePageNumber(isSelectedDefaultWorkout);
      this.isStartFetchData = true;
      return;
    },
    async handleChangePageNumber(pageNumber){
      this.page.curr = pageNumber;
      this.isStartFetchData = true;
    },
    handleResize() {
      this.optionsWidth = window.innerWidth - 40;
    },
    // handleResize:_.debounce(async function() {
    //   this.optionsWidth = window.innerWidth - 40;
    // },500),
    // utils
    searchDataByKeyword : _.debounce(async function() {
      if(!this.searchKeyword || !this.searchKeyword.length){
        await this.resetSearchKeyword();
      }
      this.params.search = this.searchKeyword;
      if(!this.isMobile()){
        this.isStartFetchData = true;
      }
    }, 600),
    fetch: async function(params){
      // console.log('params : ',params);
      const { data : participants } = await fiflboardApi.v1.getParticipants(params);
      this.participants = await participants.results;

      if(participants.results.length){
        this.page.totalRecords = participants.count;
        this.colWorkouts = this.participants[0].records.map(({ id, workout })=> {
          return { id: id,
            workout:workout };
        });
        // for mobile : but alive for screen width change.
        this.setTargetColumData();
      }
    },
    isMobile(){
      return window.innerWidth <= 768;
    },
    async handleDesktopWorkoutFilter({ label }){
      console.log(label);
      if(this.isMobile()){
        return;
      }
      let targetId = -1;
      if(label === 'POINT' || label === 'RANK'){
        targetId = -1;
      }else if(label === 'NAME'){
        return;
      }else{
        const [target] = this.colWorkouts.filter(({ workout })=> workout.name===label);
        if(!target){
          return;
        }
        targetId = target.workout.id;
      }
      this.resetPageNumber();
      this.selectedWorkout = targetId;
      this.isStartFetchData = true;
    },
    setTargetColumData(){
      if(this.selectedWorkout === -1){
        this.targetColumnData = [];
      }else{
        this.targetColumnData = this.participants.map((participant)=>{
          return participant.records.filter(record=> record.workout.id === this.selectedWorkout)[0];
        });
      }
    },
    clearSearchKeyword(){
      const isNeedReFetch = this.searchKeyword.length > 0;
      this.searchKeyword = '';

      if(!this.isMobile()){
        this.resetPageNumber();
        this.resetSearchKeyword();
        if(isNeedReFetch){
          this.isStartFetchData = true;
        }
      }
    }
  }
};
</script>

<style lang='scss'>
li{
  list-style: none;
}
ul{
  padding: 0;
  margin: 0;
}

html,
body {
  box-sizing: border-box;
	margin: 0;
	padding: 0;
	letter-spacing: -0.8px;
	font-family: Roboto, Noto Sans Korean;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-overflow-scrolling: touch;
  width: 100%;
	height: calc(100% + 400px);
	touch-action: manipulation !important;
	overflow-x: hidden;

  #app{
    position: absolute;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;


    header{
      nav{
        display: flex;
        height: 65px;
        background-color: #000;
        img{
          cursor: pointer;
          width: 109.4px;
          height: 18px;
          margin: auto auto auto 200px;
        }
      }

      #fiflboard__banner-container{
        height: 400px;
        background-color: #000;
          .el-carousel__container{
            max-width: 1440px;
            margin: auto;
          }
          .el-carousel,
          .el-carousel__container,
          .el-carousel__item{
            width: 100%;
            height: 100%;
            .carousel-banners{
              object-fit: cover;
              width: 100%;
              height: 100%;
          }
          }

      }
    }

    main{
      flex: 1;

      .fiflboard__filter--wrapper.mobile{
        display: none;
      }

      .fiflboard__filter--wrapper.desktop{
        display: flex;
        flex-wrap: wrap;
      }

      .fiflboard__filter--header{
        display: flex;
        justify-content: space-between;
        padding: 0 200px;
        margin: 35px 0 0;
        h1{
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.67px;
          color: #000;
        }
        .filter-open-btn{
            display: none;
            cursor: pointer;
            width: 50px;
            height: 40px;
            margin: auto 0;
            padding: 0;
            background-color: #000;
            color: #fff;
            border: 1px solid #000;
            border-radius: 8px;

            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
        }
      }

      .fiflboard__filter--wrapper{
        margin: 20px 0;
        padding: 0 200px;
        input.el-input__inner{
          height: 50px;
      }
      
      input.el-input__inner::placeholder{
        color: #4a4a4a;
      }

      .fiflboard__filter--year,
      .fiflboard__filter--sort
      {
        width: 110px;
        margin-right: 15px;
      }

      .fiflboard__filter--competition{
        width: 330px;
        margin-right: 15px;
        margin-bottom: 10px;
      }

      .fiflboard__filter--division{
        width: 220px;
        margin-right: 15px;
      }

      .fiflboard__search--search-box{        
        .el-input__inner{
          padding-left: 15px;
          padding-right: 30px;
        }
        .el-input__prefix{
          left: initial;
          right: 5px;
        }
      }

      .fiflboard__search--search-box.el-input{
        width: 220px;
      }

      .search-btn{
        width: 100%;
        background-color: #c6c6c6;
        border: solid 1px #d3d3d3;
        color: #4a4a4a;
      }
      }
      .main__table-container{
        padding: 0 200px;
        margin-bottom: 100px;
        .main__table--desktop{
          display: block;
        }

        .main__table--mobile{
          display : none;
        }
      }
    }

    footer{
      position: relative;
      display: flex;
      height: 280px;
      background-color: #f5f5f6;

      h5 {
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.5px;
          color: #000;
          margin: 0 0 7px 0;
      }

      a {
          cursor: pointer;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.87;
          letter-spacing: -0.8px;
          color: #4a4a4a;
      }

      a:link {
        text-decoration: none;
      }

      a:visited{
        text-decoration: none;
      }

      strong, strong a {
          font-size: 12px;
          font-stretch: normal;
          font-weight: normal;
          font-style: normal;
          line-height: 1.67;
          letter-spacing: -0.5px;
          color: #9b9b9b;
      }

      #footer-logo__anchor--fifl{
        background-image: url('/image/logo/r_fifl_logo.svg');
        background-size: contain;
        width: 50px;
        height: 50px;
        margin: auto;
      }

      #footer-logo__anchor--kboxrise{
        background-image: url('/image/logo/kboxrise_logo.svg');
        background-size: contain;
        filter: invert(100%) sepia(0%) saturate(6%) hue-rotate(155deg) brightness(100%) contrast(101%);
        width: 129px;
        height: 54px;
        margin: auto;
      }

      #footer-logo__anchor--divider{
        width: 0px;
        height: 50px;
        border-left: solid 0.5px #000;
        margin: auto 24px;
      }

      article:nth-child(1){
        position: absolute;
        display: flex;
        height: 54px;
        top: 50px;
        left: 200px;
      }

      article:nth-child(2){
        position: absolute;
        bottom: 50px;
        left: 200px;
      }

      article:nth-child(3){
        position: absolute;
        display: flex;
        top: 50px;
        right: 200px;
        div:nth-child(1){
          margin-right: 77px;
        }
      }
      
      article:nth-child(4){
        position: absolute;
        bottom: 50px;
        right: 225px;
      }
    }

    .el-table{
      border-radius: 6px;
      border: solid 1px #d3d3d3;
      td{
        border: none;
      }
      tbody tr{
        pointer-events: none;
        height: 65px;
        .el-table__cell{
          vertical-align: top;
        }
      }
      .cell{
          word-break: keep-all;
      }
      .col--detail-score{
        display: flex;
        flex-direction: column;
        p{
          margin: 0;
        }
      }
      .el-table__header-wrapper{
        border-radius: 6px;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: -0.46px;
        .cell{
            color: #000;
        }
      } 
      .el-table__row{
        font-family: Noto Sans KR, sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.8px;
      }
    }

    .el-pagination{
      margin-top: 43px;
      text-align: center;
      .el-pager{
        .number{
          font-size: 14px;
          font-weight: 500;
          color: #9b9b9b;
        }
        .active{
          color:#4a4a4a;
        }
      }
    }

    @media screen and (max-width: 1024px){
      header{
        nav{
           img{
              margin: auto auto auto 100px;
            }
        }
      }
      main{
        .fiflboard__filter--header,
        .fiflboard__filter--wrapper,
        .main__table-container
        {
            padding: 0 100px;
        }
      }

      footer{
          article:nth-child(1){
            left: 100px;
          }

          article:nth-child(2){
            left: 100px;
          }

          article:nth-child(3){
            right: 100px;
          }
      
          article:nth-child(4){
            right: 125px;
          }
      }
    }

     @media screen and (max-width: 768px){
          header{
              nav{
                  height: 50px;
              img{
                  width: 79px;
                  height: 13px;
                  margin: auto auto auto 20px;
              }
              }
          }

          main{

             .fiflboard__filter--header{
                padding: 0 20px;
                margin: 25px 0;
                h1{
                  font-size: 14px;
                }

                .filter-open-btn{
                  display: block;
                }
             }
              

              .fiflboard__filter--wrapper.mobile{
                display: flex;
              }

              .fiflboard__filter--wrapper.desktop{
                  display: none;
              }

              .fiflboard__filter--wrapper{
                padding: 20px;
                width: calc(100% - 40px);
                background-color: #f5f5f6;
                flex-direction: column;
                margin: 20px auto;
                input.el-input__inner{
                  height: initial;
                }
      
      input.el-input__inner::placeholder{
        color: #4a4a4a;
      }

      .fiflboard__filter--year,
      .fiflboard__filter--competition,
      .fiflboard__filter--sort,
      .fiflboard__filter--division,
      .fiflboard__search--search-box
      {
        width: 100%;
        margin: 0 0 10px 0;
      }

      .fiflboard__search--search-box.el-input{
        width: 100%;
      }
      }    
      .main__table-container{
        padding: 0 20px;
        margin-bottom: 63px;
        
        .main__table--desktop{
          display: none;
        }

        .main__table--mobile{
          display : block;
        }
      }  
          }

      footer{
        height: 452px;
        flex-direction: column;

          a {
            font-size: 13px;
          }

          #footer-logo__anchor--fifl{
              width: 37px;
              height: 37px;
          }

          #footer-logo__anchor--kboxrise{
              width: 96px;
              height: 40px;
          }

          #footer-logo__anchor--divider{
              height: 33px;
              margin: auto 18px;
          }

          article:nth-child(1){
              order: 1;
              position: relative;
              margin: 40px auto 30px auto;
              display: flex;
              top: initial;
              left: initial;
          }

          article:nth-child(2){
              order: 4;
              position: relative;
              bottom: initial;
              left: initial;
              margin: 0 auto;
          }

          article:nth-child(3){
            order: 2;
            position: relative;
            flex-direction: column;
            top: initial;
            right: initial;
            margin: 0 auto 30px auto;
            text-align: center;
            div:nth-child(1){
              margin-right: initial;
              margin-bottom: 18px;
            }
          }
      
          article:nth-child(4){
            order: 3;
            position: relative;
            bottom: initial;
            right: initial;
            text-align: center;
            margin: 0 auto 20px auto;
          }
      }

      // element-ui
      .el-table{
          .el-table__header-wrapper{
              font-size: 9px;
              font-weight: 600;
              line-height: 1.56;
              letter-spacing: -0.45px;
          }
          .el-table__row{
              font-size: 10px;
              line-height: 1.3;
              letter-spacing: -0.57px;
          }
      }
      .el-pagination{
        margin-top: 19px;
        .el-pager li{
            min-width: 22.5px;
        }
      }
    }

    // animation.
    .fade-enter-active{
      animation: conditionalOpen .5s;
    }
    .fade-leave-active {
      animation: conditionalOpen .5s reverse;
    }
    @keyframes conditionalOpen {
        0% {
          height: 0;
        }
        100% {
          height: 300px;
        }
    }

  }
  // element-ui
  .el-select-dropdown__item.selected{
    color: #4a4a4a;
  }
  .el-select .el-input.is-focus .el-input__inner,
  .el-select .el-input__inner:focus,
  .el-input__inner:focus{
    border-color: #000;
  }
}

.desktop__option--year{
  width: 110px;
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.desktop__option--competition{
  width: 330px;
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.desktop__option--division{
  width: 220px;
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.mobile__option > span{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


</style>
